import { Component, OnInit } from '@angular/core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-banner-partners',
  templateUrl: './banner-partners.component.html',
  styleUrls: ['./banner-partners.component.scss'],
})
export class BannerPartnersComponent implements OnInit {
  faArrowDown = faArrowDown;

  constructor() {}

  ngOnInit(): void {}
}
