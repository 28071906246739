import { Component, OnInit } from '@angular/core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-banner-about-us',
  templateUrl: './banner-about-us.component.html',
  styleUrls: ['./banner-about-us.component.scss'],
})
export class BannerAboutUsComponent implements OnInit {
  faArrowDown = faArrowDown;

  constructor() {}

  ngOnInit(): void {}

  scrollToContent() {
    const element = document.getElementById('scroll-to-container');
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
