import { Component, OnInit } from '@angular/core';
import {
  faArrowDown,
  faEnvelope,
  faInfo,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  faArrowDown = faArrowDown;
  faEmail = faEnvelope;
  faPhone = faPhone;
  faInfo = faInfo;
  faMarker = faMapMarkerAlt;

  constructor() {}

  ngOnInit(): void {}
}
