import { Component, Input, OnInit } from '@angular/core';
import { CompactCompany } from './../../models/companies/company';
import { Deal } from './../../models/deals/deal';

@Component({
  selector: 'app-single-offer',
  templateUrl: './single-offer.component.html',
  styleUrls: ['./single-offer.component.scss'],
})
export class SingleOfferComponent implements OnInit {
  @Input() offer: Deal;
  @Input() company: CompactCompany;
  constructor() {}

  ngOnInit(): void {}

  calculateDiscount(fromPrice: number, forPrice: number) {
    return 100 - (forPrice / fromPrice) * 100;
  }
}
