export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  // public apiUrl = 'https://dev-pp-backoffice-webapi.azurewebsites.net/api/';
  public apiUrl = 'https://pp-backoffice-webapi.azurewebsites.net/api/';

  // 1 = prod
  // 2 = dev
  // 3 = test
  public googleSitetag = 'UA-156135640-2';
  public facebookPixel = '2456614067785078-dev';

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {}
}
