<div class="go-profit-container" [class.background]="hasBackground">
  <h2 *ngIf="showTitle">waar ga jij profiteren?</h2>
  <div class="scroll-indicator arrow-right">
    scroll voor meer partners<fa-icon [icon]="faArrowRight"></fa-icon>
  </div>
  <div class="go-profit">
    <ul class="go-profit-items" *ngIf="companies != undefined">
      <p class="no-offers" *ngIf="companies.length == 0">
        Er zijn momenteel geen partners zichtbaar. Kom later terug.
      </p>
      <app-single-offer
        *ngFor="let company of companies"
        [company]="company"
        [ngClass]="'single-offer'"
      >
      </app-single-offer>
      <div class="last" [class.loading-more]="loadingMore">
        <span *ngIf="loadingMore; else loaded">
          meer aan het ophalen
          <fa-icon [icon]="faSpinner" [spin]="true"></fa-icon>
        </span>
        <ng-template #loaded>
          <span>Laatste</span>
        </ng-template>
      </div>
    </ul>
    <ng-container *ngIf="companies == undefined">
      <div class="loading">
        <img src="/assets/img/animated/loading.gif" class="pulse" />
        <i>Aanbod aan het ophalen...</i>
      </div>
    </ng-container>
  </div>
</div>
