<div class="publications-container">
  <div class="blue-background"></div>
  <h2>Publicaties over ons</h2>
  <div class="publications">
    <ul class="publications-items">
      <li>
        <h3>LIOF</h3>
        <p>
          lees publicatie
          <a
            href="https://liof.nl/over-liof/testimonials/profiteren-wie-wil-dat-nou-niet"
            target="_blank"
            >hier</a
          >
        </p>
        <img src="assets/img/foto-publicatie.jpg" />
      </li>
      <li>
        <h3>MEER VOLGT</h3>
        <p>kom terug voor meer</p>
        <img src="assets/img/logo-square.png" />
      </li>
    </ul>
  </div>
</div>
