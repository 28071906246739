<div class="why-wrapper">
  <h2>velen gingen je voor</h2>
  <ul>
    <li>volledig kostenloos</li>
    <li>bepaal zelf wat je communiceert</li>
    <li>alle omzet direct naar jouw bedrijf</li>
    <li>plan makkelijk in jouw partnerpagina</li>
    <li>krijg hulp van jouw eigen profiteer buddy</li>
  </ul>
  <div class="buttons">
    <app-generic-button
      *ngIf="!becomePartnerPage"
      class="pulse"
      [background]="'primary'"
      [color]="'white'"
      [scale]="'x2'"
      [title]="'partner worden'"
      [url]="['/bedrijven/partner-worden']"
    >
    </app-generic-button>
    <app-generic-button
      class="pulse"
      [background]="'primary'"
      [color]="'white'"
      [scale]="'x2'"
      [title]="'neem contact op'"
      [url]="['/contact']"
    >
    </app-generic-button>
  </div>
</div>
