<form [formGroup]="formGroup" (ngSubmit)="onSubmit()" *ngIf="!subscribed">
  <label for="email">Blijf op de hoogte en mis niets</label>
  <div class="group">
    <input
      id="email"
      type="email"
      formControlName="email"
      placeholder="Voer je e-mailadres in"
      [email]="true"
    />
    <fa-icon [icon]="faArrowRight" (click)="onSubmit()"></fa-icon>
  </div>
</form>
<div class="subscribed" *ngIf="subscribed">
  Bedankt voor je inschrijving op onze nieuwsbrief!
</div>
