import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ConsumerHeaderRoutingModule } from './consumer-header-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule, ConsumerHeaderRoutingModule],
  exports: [],
})
export class ConsumerHeaderModule {}
