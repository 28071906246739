<div class="how-it-works-container">
  <h2>Hoe werkt het?</h2>
  <ul>
    <li>
      <img src="/assets/img/svgs/phone.svg" />
      <h3>Download de app</h3>
      <p>
        Download gratis op jouw mobiele telefoon, een account aanmaken is
        suuuper easy.
      </p>
    </li>
    <li>
      <img src="/assets/img/svgs/laptop.svg" />
      <h3>Kies uit het aanbod</h3>
      <p>
        In de app staat aanbod van honderden bedrijven. Als je iets ziet wat je
        wil, kun je via de app betalen of gewoon langsgaan en de app laten zien.
      </p>
    </li>
    <li>
      <img src="/assets/img/svgs/counter.svg" />
      <h3>Bespaar direct, zonder gedoe!</h3>
      <p>
        Betalingen gaan direct naar het bedrijf of je betaalt gewoon aan de
        kassa. Geen kleine lettertjes
      </p>
    </li>
  </ul>
  <img class="wiggle full" src="/assets/img/svgs/wiggle.svg" />
</div>
