// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: '1.0.0',
  production: false,
  // apiUrl: 'http://localhost:5002/api/',
  apiUrl: 'https://dev-pp-backoffice-webapi.azurewebsites.net/api/',

  // 1 = prod
  // 2 = dev
  // 3 = test
  googleSitetag: 'UA-156135640-2',
  facebookPixel: '2456614067785078-dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
