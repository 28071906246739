import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BannerBecomePartnerComponent } from '../banner-become-partner/banner-become-partner.component';
import { BannerPartnersComponent } from '../banner-partners/banner-partners.component';
import { LandingPageComponent } from './../../landing-page/landing-page.component';
import { BannerAboutUsComponent } from './../banner-about-us/banner-about-us.component';
import { BannerComponent } from './../banner/banner.component';
import { GenericButtonComponent } from './../buttons/generic-button/generic-button.component';
import { CompaniesSliderComponent } from './../companies-slider/companies-slider.component';
import { ContactFormComponent } from './../contact-form/contact-form.component';
import { CountDownComponent } from './../count-down/count-down.component';
import { FooterComponent } from './../footer/footer.component';
import { GoProfitComponent } from './../go-profit/go-profit.component';
import { GoProfitModule } from './../go-profit/go-profit.module';
import { ConsumerHeaderComponent } from './../headers/consumer-header/consumer-header.component';
import { ConsumerHeaderModule } from './../headers/consumer-header/consumer-header.module';
import { HowItWorksPartnersComponent } from './../how-it-works-partners/how-it-works-partners.component';
import { HowItWorksComponent } from './../how-it-works/how-it-works.component';
import { NewsletterFormComponent } from './../newsletter-form/newsletter-form.component';
import { NumbersComponent } from './../numbers/numbers.component';
import { SeasonPipe } from './../pipes/season.pipe';
import { ProfessionalBannerComponent } from './../professional-banner/professional-banner.component';
import { PublicationsComponent } from './../publications/publications.component';
import { SeasonBannerComponent } from './../season-banner/season-banner.component';
import { ThisIsUsComponent } from './../this-is-us/this-is-us.component';
import { WhyComponent } from './../why/why.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    CountDownComponent,
    NewsletterFormComponent,
    ConsumerHeaderComponent,
    GenericButtonComponent,
    BannerComponent,
    BannerAboutUsComponent,
    BannerPartnersComponent,
    BannerBecomePartnerComponent,
    SeasonPipe,
    SeasonBannerComponent,
    FooterComponent,
    HowItWorksComponent,
    GoProfitComponent,
    ContactFormComponent,
    ProfessionalBannerComponent,
    WhyComponent,
    NumbersComponent,
    HowItWorksPartnersComponent,
    CompaniesSliderComponent,
    PublicationsComponent,
    ThisIsUsComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ConsumerHeaderModule,
    GoProfitModule,
  ],
  exports: [
    LandingPageComponent,
    CountDownComponent,
    NewsletterFormComponent,
    ConsumerHeaderComponent,
    GenericButtonComponent,
    BannerComponent,
    BannerAboutUsComponent,
    BannerPartnersComponent,
    BannerBecomePartnerComponent,
    SeasonPipe,
    SeasonBannerComponent,
    FooterComponent,
    HowItWorksComponent,
    GoProfitComponent,
    ContactFormComponent,
    ProfessionalBannerComponent,
    WhyComponent,
    NumbersComponent,
    HowItWorksPartnersComponent,
    CompaniesSliderComponent,
    PublicationsComponent,
    ThisIsUsComponent,
  ],
})
export class SharedModule {}
