<div class="professional-banner-container">
  <div
    class="professional-banner"
    [class.clickable]="!becomePartnerPage"
    (click)="goToBecomePartnerPage()"
  >
    <div class="content">
      <h2>sluit je aan bij de professionals</h2>
    </div>
    <img [src]="'/assets/img/seasons/download-app/||season||.svg' | season" />
  </div>
</div>
