import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from './shared/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'visitor';
  constructor(
    private router: Router,
    private analyticsService: AnalyticsService
  ) {}
  async ngOnInit() {
    this.router.events.subscribe(async (val) => {
      // see also
      if (val instanceof NavigationEnd) {
        const a = await this.analyticsService.logEvent(
          'page navigation',
          val.url,
          'navigated to ' + val.url
        );

        a.subscribe((x) => {
          console.log(x);
        });
      }
    });
  }
}
