<div class="banner">
  <img src="assets/img/landing.png" />
  <div class="content">
    <h1>100% voordeel met Profiteerpas</h1>
    <div class="columns">
      <div>
        <p>
          Je kent het wel, al die platformen waarop jij je ‘’deal’’ kunt
          verkopen. Maarja, vaak moet je 50% korting geven en daarop nog eens
          30% commissie afstaan. Dan komt de belasting nog eens en jouw
          kostprijs. Zo blijft er dus niets over. Dit doen we bij Profiteerpas
          helemaal anders. Profiteerpas is namelijk helemaal gratis voor jou. Je
          leest het goed, geen maandelijkse kosten, geen commissiekosten en je
          bepaalt zelf wat je op het platform plaatst. Als iets via de app
          verkocht wordt, gaat de omzet hiervan direct, 100% naar jou.
        </p>
      </div>
      <div>
        <p>
          Dit komt doordat Profiteerpas gefinancierd wordt via jouw gemeente.
          Ben jij al overtuigd? Partner worden is heel makkelijk! Schrijf je in
          via ons inschrijfformulier en wij nemen zo snel mogelijk contact met
          je op. Je krijgt dan een eigen backoffice waar je alles zelf makkelijk
          kunt regelen. Lukt dit niet, laat het ons dan gerust weten, dan komen
          we gewoon binnen en helpen je!<br /><br /><b
            >Tot snel, team Profiteerpas</b
          >
        </p>
      </div>
    </div>
    <div class="buttons">
      <app-generic-button
        class="pulse"
        [background]="'primary'"
        [color]="'white'"
        [scale]="'x2'"
        [title]="'partner worden'"
        [url]="['/bedrijven/partner-worden']"
      >
      </app-generic-button>
      <app-generic-button
        class="pulse"
        [background]="'primary'"
        [color]="'white'"
        [scale]="'x2'"
        [title]="'neem contact op'"
        [url]="['/contact']"
      >
      </app-generic-button>
    </div>
  </div>
</div>
