<div class="banner">
  <img src="assets/img/landing.png" />
  <div class="content">
    <h1>100% voordeel met Profiteerpas</h1>
    <form [formGroup]="formGroup" (ngSubmit)="processForm()">
      <input
        type="hidden"
        name="promocode"
        class="input form-control"
        placeholder="Geef een promocode op"
        formControlName="promocode"
      />
      <!-- name -->
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="first">Je voornaam</label>
            <input
              type="text"
              name="firstname"
              class="input form-control"
              placeholder="Geef je voornaam op"
              formControlName="firstname"
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label for="lastname">Je achternaam</label>
            <input
              type="text"
              name="lastname"
              class="input form-control"
              placeholder="Geef je achternaam op"
              formControlName="lastname"
            />
          </div>
        </div>
      </div>

      <!-- email -->
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="email">Je e-mailadres</label>
            <input
              type="email"
              name="email"
              class="input form-control"
              placeholder="Geef je e-mailadres op"
              aria-describedby="emailHelp"
              formControlName="email"
              [email]="true"
            />
          </div>
        </div>
      </div>

      <!-- email -->
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="phonenumber">Je telefoonnummer</label>
            <input
              type="text"
              name="phonenumber"
              class="input form-control"
              placeholder="Geef een telefoonnummer op"
              formControlName="phonenumber"
            />
          </div>
        </div>
      </div>

      <!-- message -->
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="company">Je bedrijfsnaam</label>
            <input
              type="text"
              class="input form-control"
              name="company"
              rows="6"
              placeholder="Geef je bedrijfsnaam op"
              formControlName="company"
            />
          </div>
        </div>
      </div>

      <div class="form-group" *ngIf="emailSent">
        <div class="success">
          <p>
            Aanmelding verstuurd, u ontvangt een kopie op {{ emailReceiver }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group">
            <button
              type="submit"
              class="button is-danger is-large"
              [disabled]="emailSent || formGroup.invalid"
            >
              aanmelden
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
