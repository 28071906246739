<div class="wrapper">
  <app-consumer-header></app-consumer-header>
  <div class="banner">
    <img src="assets/img/landing.png" />
    <div class="content">
      <div class="l">
        <h1>WIJ WERKEN HARD</h1>
        <h2>
          Momenteel werken wij aan een compleet vernieuwd Profiteerpas. Hoe het
          precies uit gaat zien en welke voordelen je er aan hebt, verklappen we
          nog even niet. Maar wij kunnen niet wachten om jou mee te nemen in
          onze nieuwe strategie om je nog meer te laten genieten van toffe
          aanbiedingen!
        </h2>
        <app-newsletter-form></app-newsletter-form>
        <div class="socials">
          <a href="https://www.facebook.com/Profiteerpas" target="_blank">
            <fa-icon [icon]="facebook"></fa-icon>
          </a>
          <a href="https://www.instagram.com/profiteerpas.nl/" target="_blank">
            <fa-icon [icon]="instagram"></fa-icon>
          </a>
          <a
            href="https://open.spotify.com/playlist/3aNJswU6UbWn35Dy5RTmw2?si=15e7ac44309f4c4e"
            target="_blank"
          >
            <fa-icon [icon]="spotify"></fa-icon>
          </a>
        </div>
      </div>
      <div class="r">
        <img src="assets/img/telefoon_with_text.png" />
      </div>
    </div>
  </div>

  <footer>
    &copy; Profiteerpas 2022 all rights reserved | Profiteerpas B.V. |&nbsp;
    <a class="mailto" href="mailto:info@profiteerpas.nl">
      info@profiteerpas.nl
    </a>
    &nbsp; | kvk-nr: 78249481 | Bergerstraat 51, 6226 BA, Maastricht
  </footer>
</div>
