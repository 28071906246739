import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-why',
  templateUrl: './why.component.html',
  styleUrls: ['./why.component.scss'],
})
export class WhyComponent implements OnInit {
  @Input() becomePartnerPage = false;

  constructor() {}

  ngOnInit(): void {}
}
