import { Component, OnInit } from '@angular/core';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-this-is-us',
  templateUrl: './this-is-us.component.html',
  styleUrls: ['./this-is-us.component.scss'],
})
export class ThisIsUsComponent implements OnInit {
  faLinkedIn = faLinkedin;
  constructor() {}

  ngOnInit(): void {}
}
