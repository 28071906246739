<div class="numbers-wrapper">
  <div class="card">
    <h2>partnercijfers</h2>
    <div class="row">
      <div class="col">
        <h3>Nederland</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table>
          <tr>
            <td>Maastricht</td>
            <td>56</td>
          </tr>
          <tr>
            <td>Beek</td>
            <td>12</td>
          </tr>
          <tr>
            <td>Gennep</td>
            <td>35</td>
          </tr>
          <tr>
            <td>Heerlen</td>
            <td>32</td>
          </tr>
          <tr>
            <td>Leudal</td>
            <td>19</td>
          </tr>
          <tr>
            <td>Nederweert</td>
            <td>72</td>
          </tr>
          <tr>
            <td>Roermond</td>
            <td>106</td>
          </tr>
        </table>
      </div>
      <div class="col">
        <table>
          <tr>
            <td>Maastricht</td>
            <td>56</td>
          </tr>
          <tr>
            <td>Beek</td>
            <td>12</td>
          </tr>
          <tr>
            <td>Gennep</td>
            <td>35</td>
          </tr>
          <tr>
            <td>Heerlen</td>
            <td>32</td>
          </tr>
          <tr>
            <td>Leudal</td>
            <td>19</td>
          </tr>
          <tr>
            <td>Nederweert</td>
            <td>72</td>
          </tr>
          <tr>
            <td>Roermond</td>
            <td>106</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h3>België</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table>
          <tr>
            <td>Maasmechelen</td>
            <td>67</td>
          </tr>
          <tr>
            <td>Antwerpen</td>
            <td>89</td>
          </tr>
        </table>
      </div>
      <div class="col">
        <table>
          <tr>
            <td>Maasmechelen</td>
            <td>67</td>
          </tr>
          <tr>
            <td>Antwerpen</td>
            <td>89</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
