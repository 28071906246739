import { Component, OnInit } from '@angular/core';
import {
  faFacebook,
  faInstagram,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  public dDay = new Date('Jun 1 2022 00:00:00');

  facebook = faFacebook;
  instagram = faInstagram;
  spotify = faSpotify;

  constructor() {}

  ngOnInit(): void {}
}
