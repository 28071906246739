import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'season',
})
export class SeasonPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    let dDay = new Date();
    const spring = new Date(dDay.getFullYear(), 2, 21);
    const summer = new Date(dDay.getFullYear(), 5, 21);
    const autumn = new Date(dDay.getFullYear(), 8, 21);

    let seasonS = 'winter';

    if (dDay >= autumn) {
      seasonS = 'autumn';
    } else if (dDay >= summer) {
      seasonS = 'summer';
    } else if (dDay >= spring) {
      seasonS = 'spring';
    }

    value = value.replace('||season||', seasonS);
    return value;
  }
}
