<div class="how-it-works-partners-wrapper">
  <div class="row">
    <div class="col">
      <img src="/assets/img/svgs/hoe-werkt-het.svg" />
    </div>
    <div class="col">
      <h2>Hoe werkt het?</h2>
      <p>
        Zodra jij je hebt aangemeld nemen we contact met jou op. Je krijgt dan
        toegang tot jouw eigen back-office. Hier kun je gemakkelijk je eigen
        profiel aanmaken en al je gegevens invullen. Ook kun je in onze tool
        heel makkelijk aanbod aanmaken. Dit loopt vervolgens volledig
        automatisch en is heel flexibel. Zo kun je aanbod inplannen voor een
        heel jaar vooruit, maar kun je ook iets tot op de seconde precies
        plaatsen. Alles komt direct zodra jij het wilt op de site en in de app.
        Neem gerust contact met ons op als je hulp nodig hebt, we helpen je
        graag!
      </p>

      <div class="buttons">
        <app-generic-button
          *ngIf="!becomePartnerPage"
          class="pulse"
          [background]="'primary'"
          [color]="'white'"
          [scale]="'x2'"
          [title]="'partner worden'"
          [url]="['/bedrijven/partner-worden']"
        >
        </app-generic-button>
        <app-generic-button
          class="pulse"
          [background]="'primary'"
          [color]="'white'"
          [scale]="'x2'"
          [title]="'neem contact op'"
          [url]="['/contact']"
        >
        </app-generic-button>
      </div>
    </div>
  </div>
</div>
