import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  env: any;
  constructor(private http: HttpClient) {
    this.env = environment;
  }

  public async logEvent(
    type: string,
    page: string,
    description: string
  ): Promise<Observable<boolean>> {
    const ipAddress = await firstValueFrom(
      this.http.get<{ ip: string }>('https://api.ipify.org/?format=json')
    );

    const data = {
      uuid: ipAddress.ip,
      type: type,
      event: 'navigated to page',
      page: page,
      description: description,
      ipAddress: ipAddress.ip,
      latitude: '',
      longitude: '',
      platform: `web - ${this.getBrowserName()}`,
      platformVersion: this.getBrowserVersion(),
      appVersion: this.env.version.toString(),
      city: '',
      dealID: 0,
      companyID: 0,
      companyHint: '',
      dealHint: '',
    };

    return this.http.post<boolean>(`${this.env.apiUrl}analytics`, data);
  }

  private getBrowserName(): string {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'IE';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Other';
    }
  }

  private getBrowserVersion(): string {
    var userAgent = navigator.userAgent,
      tem,
      matchTest =
        userAgent.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest = matchTest[2]
      ? [matchTest[1], matchTest[2]]
      : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = userAgent.match(/version\/(\d+)/i)) != null)
      matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }
}
