import { Component, Input, OnInit } from '@angular/core';
import { CompactCompany } from './../models/companies/company';
import { Deal } from './../models/deals/deal';

@Component({
  selector: 'app-go-profit',
  templateUrl: './go-profit.component.html',
  styleUrls: ['./go-profit.component.scss'],
})
export class GoProfitComponent implements OnInit {
  @Input() offers: Deal[] | undefined = undefined;
  @Input() companies: CompactCompany[] | undefined = undefined;

  constructor() {}

  ngOnInit(): void {}

  rotateRandom(min: number, max: number): string {
    let deg = Math.floor(Math.random() * (max - min) + min);
    // return deg < 0 ? `${Math.abs(deg)}i` : `${deg}`;
    return deg < 0 ? '5i' : '5';
  }

  generateArray(amount: number): number[] {
    let t = [];
    for (let i = 0; i < amount; i++) {
      t.push(i);
    }
    return t;
  }
}
