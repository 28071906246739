<a
  [ngClass]="scale"
  [routerLink]="url"
  [title]="title"
  [class.background-primary]="background == 'primary'"
  [class.background-primary-accent]="background == 'primary-accent'"
  [class.background-white]="background == 'white'"
  [class.color-primary]="color == 'primary'"
  [class.color-primary-accent]="color == 'primary-accent'"
  [class.color-white]="color == 'white'"
  [class.align-left]="align == 'left'"
  [class.align-center]="align == 'center'"
  [class.align-right]="align == 'right'"
  [class.border-full]="border == 'full'"
  [class.border-half]="border == 'half'"
  [class.border-quarter]="border == 'quarter'"
  >{{ title }}</a
>
