import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss'],
})
export class GenericButtonComponent implements OnInit {
  @Input() url: string | any[] = '';
  @Input() title: string = '';
  @Input() scale: string = 'x1';
  @Input() background: string = 'white';
  @Input() color: string = 'primary';
  @Input() border: string = 'full';
  @Input() align: string = 'center';
  constructor() {}

  ngOnInit(): void {}
}
