<div class="banner">
  <img src="assets/img/landing.png" />
  <div class="content">
    <div class="l">
      <h1>extra genieten<br />met profiteerpas</h1>

      <app-generic-button
        [url]="['/aanbod']"
        [title]="'Bekijk hier het beste aanbod'"
        [background]="'primary'"
        [color]="'white'"
        [align]="'center'"
        [scale]="'x2'"
      ></app-generic-button>
      <div class="buttons ball">
        <span href="#scroll" (click)="scrollToContent()">
          <fa-icon [icon]="faArrowDown"></fa-icon>
        </span>
      </div>
    </div>
    <div class="r">
      <div class="phone-container">
        <div class="phone-content">
          <img src="/assets/img/73960_Profiteerpas_H_09.png" />
          <div class="phone-buttons">
            <a [routerLink]="'/binnenkort'" title="Get it on iTunes">
              <img src="/assets/img/get_it_on_apple.svg" />
            </a>
            <a [routerLink]="'/binnenkort'" title="Get it on Google">
              <img src="/assets/img/get_it_on_google.svg" />
            </a>
          </div>
        </div>
        <img src="/assets/img/telefoon_with_background.png" />
      </div>
    </div>
  </div>
</div>
<div id="scroll-to-container"></div>
