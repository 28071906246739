<div class="banner">
  <img src="assets/img/landing.png" />
  <div class="content">
    <div class="r">
      <div class="phone-container">
        <div class="phone-content">
          <div class="phone-buttons">
            <a [routerLink]="'/binnenkort'" title="Get it on iTunes">
              <img src="/assets/img/get_it_on_apple.svg" />
            </a>
            <a [routerLink]="'/binnenkort'" title="Get it on Google">
              <img src="/assets/img/get_it_on_google.svg" />
            </a>
          </div>

          <div class="card-wrapper">
            <div class="card">
              <h1>we horen graag van je!</h1>
              <ul>
                <li>
                  <div class="icon-wrapper">
                    <fa-icon [icon]="faMarker"></fa-icon>
                  </div>
                  <span>
                    Brightlands Smart Services Campus<br />
                    Smedestraat 2, 6411 CR Heerlen
                  </span>
                </li>
                <!-- <li>
                  <div class="icon-wrapper">
                    <fa-icon [icon]="faPhone"></fa-icon>
                  </div>
                  <span>0031 (0)43 577 862</span>
                </li> -->
                <li>
                  <div class="icon-wrapper">
                    <fa-icon [icon]="faEmail"></fa-icon>
                  </div>
                  <span
                    ><a href="mailto:info@profiteerpas.nl">
                      info@profiteerpas.nl
                    </a></span
                  >
                </li>
                <li>
                  <div class="icon-wrapper">
                    <fa-icon [icon]="faInfo"></fa-icon>
                  </div>
                  <span
                    >Profiteerpas B.V.<br />
                    kvk: 861317877<br />
                    Iban: NL 79 INGB 0008 0780 19</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <img class="phone" src="/assets/img/telefoon_with_background.png" />
      </div>
    </div>
  </div>
</div>
