<div class="go-profit-container">
  <div class="go-profit">
    <h2>waar ga jij profiteren?</h2>
    <ul class="go-profit-items" *ngIf="offers != undefined">
      <p class="no-offers" *ngIf="offers.length == 0">
        Er is nog geen aanbod beschikbaar. Kom later terug.
      </p>
      <app-single-offer
        *ngFor="let offer of offers"
        [offer]="offer"
        [ngClass]="'single-offer rotate-' + rotateRandom(-10, 10)"
      >
      </app-single-offer>
    </ul>
    <ul class="go-profit-items" *ngIf="companies != undefined">
      <p class="no-offers" *ngIf="companies.length == 0">
        Er zijn momenteel geen partners zichtbaar. Kom later terug.
      </p>
      <app-single-offer
        *ngFor="let company of companies"
        [company]="company"
        [ngClass]="'single-offer'"
      >
      </app-single-offer>
    </ul>
    <ng-container *ngIf="offers == undefined && companies == undefined">
      <div class="loading">
        <img src="/assets/img/animated/loading.gif" class="pulse" />
        <i>Aanbod aan het ophalen...</i>
      </div>
    </ng-container>
  </div>
</div>
