import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Subscriber } from '../models/subscriber';
import { NewsletterService } from './../services/newsletter.service';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss'],
})
export class NewsletterFormComponent implements OnInit {
  faArrowRight = faArrowRight;
  public visible = false;
  public subscribed = false;

  formGroup = new FormGroup({
    email: new FormControl('', Validators.required),
  });
  constructor(private newsletterService: NewsletterService) {}

  ngOnInit(): void {}

  onSubmit(): void {
    const subscriber: Subscriber = {
      email: this.formGroup.get('email')?.value,
      name: 'geïnteresseerde',
    };
    console.log(subscriber);
    this.newsletterService.subscribe(subscriber).subscribe((success) => {
      if (success) {
        this.isSubscribed();
      }
    });
  }

  isSubscribed(): void {
    this.subscribed = true;
  }
}
