import { Component, Input, OnInit } from '@angular/core';
import { faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CompactCompany } from '../models/companies/company';

@Component({
  selector: 'app-companies-slider',
  templateUrl: './companies-slider.component.html',
  styleUrls: ['./companies-slider.component.scss'],
})
export class CompaniesSliderComponent implements OnInit {
  faArrowRight = faArrowRight;
  faSpinner = faSpinner;

  @Input() companies: CompactCompany[] | undefined = undefined;
  @Input() loadingMore: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() hasBackground: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
