import { Component, OnInit } from '@angular/core';
import {
  faAppStore,
  faFacebookF,
  faGooglePlay,
  faInstagram,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-consumer-header',
  templateUrl: './consumer-header.component.html',
  styleUrls: ['./consumer-header.component.scss'],
})
export class ConsumerHeaderComponent implements OnInit {
  faGooglePlayStore = faGooglePlay;
  faInstagram = faInstagram;
  faSpotify = faSpotify;
  faAppleAppStore = faAppStore;
  faFacebook = faFacebookF;
  faBars = faBars;

  menuVisible = false;

  constructor() {}

  ngOnInit(): void {}

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }
}
