<div class="this-is-us">
  <h1>Dit zijn wij</h1>
  <div class="row top">
    <div class="col">
      <img src="assets/img/profiteerpas-04837.jpg" />
    </div>
    <div class="col text">
      <h2>samen & eerlijk</h2>
      <p>
        Samen staan we sterk en bereiken we veel meer. Transparantie en
        eerlijkheid staan bij ons hoog in het vaandel. Géén addertjes onder het
        gras en géén loze beloftes.
      </p>
    </div>
  </div>
  <div class="row center">
    <div class="col text">
      <h2>innoveren</h2>
      <p>
        Innovatie zit in ons DNA. Zo is profiteerpas geen advertentieplatform
        maar een tool voor de ondernemer om nóg meer te bereiken. Wij beloven
        Profiteerpas constant te blijven verbeteren.
      </p>
    </div>
    <div class="col">
      <img src="assets/img/profiteerpas-04896.jpg" />
    </div>
  </div>
  <div class="row bottom">
    <div class="col">
      <img src="assets/img/keanu.jpg" />
      <div class="text">
        <h2>
          Keanu
          <a
            href="https://www.linkedin.com/in/keanu-franssen-b95257185/"
            target="_blank"
          >
            <fa-icon [icon]="faLinkedIn"></fa-icon>
          </a>
        </h2>
        <p>
          Van nature ben ik betrokken, geïnteresseerd, productief en behulpzaam.
          Het ontwikkelen van oplossingen voor diverse operationele en
          strategische problemen is wat ik graag doe. Ik vind mijzelf een
          veelzijdig persoon en denk dat mijn kwaliteiten zich goed vertalen op
          diverse gebieden. Een nieuwe manier van denken is mijn kracht.
        </p>
      </div>
    </div>
    <div class="col">
      <div class="text">
        <h2>
          Tom
          <a
            href="https://www.linkedin.com/in/tom-de-la-rambelje-4511a913a/"
            target="_blank"
          >
            <fa-icon [icon]="faLinkedIn"></fa-icon>
          </a>
        </h2>
        <p>
          Met mijn visionair karakter, nieuwsgierigheid en positieve instelling
          lees ik perfect tussen de regels door. Dit versterkt mijn
          communicatieve vaardigheden. Op de werkvloer ben ik perfect in staat
          om te schakelen tussen een gepassioneerde en gedreven idealist. Privé
          ben ik een creatieve, enthousiaste vrij geest.
        </p>
      </div>
      <img src="assets/img/tom.jpg" />
    </div>
  </div>
</div>
