<div class="single-offer" *ngIf="!!offer">
  <img [src]="offer.deal.headerImageSource" />
  <div class="overlay">
    <div class="phone-buttons">
      <a [routerLink]="'/binnenkort'" title="Get it on iTunes">
        <img src="/assets/img/get_it_on_apple.svg" />
      </a>
      <a [routerLink]="'/binnenkort'" title="Get it on Google">
        <img src="/assets/img/get_it_on_google.svg" />
      </a>
    </div>
    <div class="content-container">
      <div class="offer-details">
        <h3>{{ offer.companyName }}</h3>
        <h4>{{ offer.deal.shortTitle }}</h4>
      </div>
      <div class="price-container">
        <h5
          class="from-price"
          [class.strike-trough]="offer.deal.forPrice > 0"
          *ngIf="offer.deal.fromPrice"
        >
          &euro;{{ offer.deal.fromPrice }},-
        </h5>
        <h5 class="for-price" *ngIf="offer.deal.forPrice">
          &euro;{{ offer.deal.forPrice }},-
        </h5>
      </div>
    </div>
  </div>
</div>

<div class="single-offer" *ngIf="company">
  <img [src]="company.headerImageSource" />
  <div class="overlay">
    <div class="phone-buttons">
      <a [routerLink]="'/binnenkort'" title="Get it on iTunes">
        <img src="/assets/img/get_it_on_apple.svg" />
      </a>
      <a [routerLink]="'/binnenkort'" title="Get it on Google">
        <img src="/assets/img/get_it_on_google.svg" />
      </a>
    </div>
    <div class="content-container">
      <h3>{{ company.name }}</h3>
    </div>
  </div>
</div>
