import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BecomePartnerForm } from '../models/become-partner-form';
import { ContactForm } from '../models/contact-form';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  result: any;
  env: any;
  constructor(private http: HttpClient) {
    this.env = environment;
  }

  async sendContactForm(form: ContactForm) {
    this.http
      .post<any>(`${this.env.apiUrl}Support/Contact`, form)
      .subscribe((data) => {
        this.result = data;
      });
  }

  async sendPartnerForm(form: BecomePartnerForm) {
    this.http
      .post<any>(`${this.env.apiUrl}Support/BecomePartner`, form)
      .subscribe((data) => {
        this.result = data;
      });
  }
}
