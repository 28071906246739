<header>
  <div class="center-container menu-l">
    <a [routerLink]="['/']">
      <img class="logo" src="/assets/img/73960_Profiteerpas_H_17.png" />
    </a>
    <nav>
      <ul>
        <li>
          <a [routerLink]="['/']" title="Home">home</a>
        </li>
        <li>
          <a [routerLink]="['/aanbod']" title="Aanbod">aanbod</a>
        </li>
        <li>
          <a [routerLink]="['/contact']" title="Contact">contact</a>
        </li>
        <li>
          <a [routerLink]="['/over-ons']" title="Over ons">over ons</a>
        </li>
      </ul>
    </nav>
    <div class="social-media-container">
      <ul>
        <li>
          <a
            href="https://www.facebook.com/Profiteerpas"
            title="Facebook"
            target="_blank"
          >
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/playlist/1dK5H2dv49lITRpIuc6MTQ?si=63bba1d130fb4e27"
            title="Spotify"
            target="_blank"
          >
            <fa-icon [icon]="faSpotify"></fa-icon>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/profiteerpas.nl/"
            title="Instagram"
            target="_blank"
          >
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/binnenkort']"
            title="Google Play Store"
            target="_blank"
          >
            <fa-icon [icon]="faGooglePlayStore"></fa-icon>
          </a>
        </li>
        <li>
          <a
            [routerLink]="['/binnenkort']"
            title="Apple App Store"
            target="_blank"
          >
            <fa-icon [icon]="faAppleAppStore"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
    <div class="buttons-container">
      <ul>
        <li>
          <app-generic-button
            [url]="['/bedrijven']"
            [title]="'Voor bedrijven'"
          ></app-generic-button>
        </li>
      </ul>
    </div>
  </div>
  <div class="center-container menu-s">
    <a [routerLink]="['/']">
      <img class="logo" src="/assets/img/73960_Profiteerpas_H_17.png" />
    </a>
    <nav class="expand-menu" [class.menu-visible]="menuVisible">
      <ul>
        <li>
          <a [routerLink]="['/']" title="Home">home</a>
        </li>
        <li>
          <a [routerLink]="['/aanbod']" title="Aanbod">aanbod</a>
        </li>
        <li>
          <a [routerLink]="['/contact']" title="Contact">contact</a>
        </li>

        <li>
          <app-generic-button
            [url]="['/bedrijven']"
            [title]="'Voor bedrijven'"
          ></app-generic-button>
        </li>
        <li class="socials">
          <a
            href="https://www.facebook.com/Profiteerpas"
            title="Facebook"
            target="_blank"
          >
            <fa-icon [icon]="faFacebook"></fa-icon>
          </a>
          <a
            href="https://open.spotify.com/playlist/1dK5H2dv49lITRpIuc6MTQ?si=63bba1d130fb4e27"
            title="Spotify"
            target="_blank"
          >
            <fa-icon [icon]="faSpotify"></fa-icon>
          </a>
          <a
            href="https://www.instagram.com/profiteerpas.nl/"
            title="Instagram"
            target="_blank"
          >
            <fa-icon [icon]="faInstagram"></fa-icon>
          </a>
          <a
            [routerLink]="['/binnenkort']"
            title="Google Play Store"
            target="_blank"
          >
            <fa-icon [icon]="faGooglePlayStore"></fa-icon>
          </a>
          <a
            [routerLink]="['/binnenkort']"
            title="Apple App Store"
            target="_blank"
          >
            <fa-icon [icon]="faAppleAppStore"></fa-icon>
          </a>
        </li>
      </ul>
    </nav>
    <div class="flex-filler"></div>
    <div class="menu-toggler" (click)="toggleMenu()">
      <fa-icon [icon]="faBars"></fa-icon>
    </div>
  </div>
</header>
