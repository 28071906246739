import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-professional-banner',
  templateUrl: './professional-banner.component.html',
  styleUrls: ['./professional-banner.component.scss'],
})
export class ProfessionalBannerComponent implements OnInit {
  @Input() becomePartnerPage = false;
  constructor(private router: Router) {}

  ngOnInit(): void {}

  goToBecomePartnerPage(): void {
    if (!this.becomePartnerPage) {
      this.router.navigate(['/bedrijven/partner-worden']);
    }
  }
}
