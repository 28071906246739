import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { BecomePartnerForm } from '../models/become-partner-form';
import { FormsService } from '../services/forms.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';

@Component({
  selector: 'app-banner-become-partner',
  templateUrl: './banner-become-partner.component.html',
  styleUrls: ['./banner-become-partner.component.scss'],
})
export class BannerBecomePartnerComponent implements OnInit {
  faArrowDown = faArrowDown;

  curDate = new Date();

  formGroup = new FormGroup({
    firstname: new FormControl('', Validators.required),
    lastname: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phonenumber: new FormControl('', Validators.required),
    promocode: new FormControl(this.curDate.getFullYear(), Validators.required),
    company: new FormControl('', Validators.required),
  });

  emailSent = false;
  invalid = true;
  emailReceiver = '';

  constructor(
    private formsService: FormsService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {}

  processForm() {
    const form: BecomePartnerForm = {
      firstname: this.formGroup.get('firstname')?.value,
      lastname: this.formGroup.get('lastname')?.value,
      email: this.formGroup.get('email')?.value,
      phonenumber: this.formGroup.get('phonenumber')?.value,
      promocode: this.formGroup.get('promocode')?.value,
      company: this.formGroup.get('company')?.value,
    };
    this.emailReceiver = form.email;
    this.googleAnalyticsService.eventEmitter(
      'send_become_partner_form',
      'become_partner',
      'send_form',
      'send',
      1
    );
    this.formsService.sendPartnerForm(form);
    this.emailSent = true;
  }

  get firstname(): any {
    return this.formGroup.get('firstname');
  }
  get lastname(): any {
    return this.formGroup.get('lastname');
  }
  get email(): any {
    return this.formGroup.get('email');
  }
  get phonenumber(): any {
    return this.formGroup.get('phonenumber');
  }
  get promocode(): any {
    return this.formGroup.get('promocode');
  }
  get company(): any {
    return this.formGroup.get('company');
  }
}
