<footer>
  <div class="footer-container">
    <ul>
      <li>
        &copy; Profiteerpas B.V. {{ date | date: "Y" }}. All Rights Reserved.
      </li>
      <li>
        <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
      </li>
      <li>
        <a [routerLink]="['/terms-and-conditions']">Algemene voorwaarden</a>
      </li>
    </ul>
  </div>
</footer>
