import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscriber } from '../models/subscriber';

const DOMAIN_ID = '10';

interface ContentSerivceInterface {
  subscribe(subscriber: Subscriber): Observable<boolean>;
  unsubscribe(guid: string): Observable<boolean>;
  verify(guid: string): Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class NewsletterService implements ContentSerivceInterface {
  constructor(private http: HttpClient) {}

  subscribe(subscriber: Subscriber): Observable<boolean> {
    const formData = new FormData();
    formData.append('email', subscriber.email);
    formData.append('name', subscriber.name);
    formData.append('type', 'new_subscriber');
    formData.append('should_verify', '0');
    formData.append('domain_id', DOMAIN_ID);

    return this.http.post<boolean>(
      `https://dev.api.ipolos.nl/newsletter`,
      formData
    );
  }

  unsubscribe(guid: string): Observable<boolean> {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('type', 'unsubscribe_subscriber');
    formData.append('domain_id', DOMAIN_ID);

    return this.http.post<boolean>(
      `https://dev.api.ipolos.nl/newsletter/unsubscribe`,
      formData
    );
  }

  verify(guid: string): Observable<boolean> {
    const formData = new FormData();
    formData.append('guid', guid);
    formData.append('type', 'verify_subscriber');
    formData.append('domain_id', DOMAIN_ID);

    return this.http.post<boolean>(
      `https://dev.api.ipolos.nl/newsletter/verify`,
      formData
    );
  }
}
