import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-how-it-works-partners',
  templateUrl: './how-it-works-partners.component.html',
  styleUrls: ['./how-it-works-partners.component.scss'],
})
export class HowItWorksPartnersComponent implements OnInit {
  @Input() becomePartnerPage = false;

  constructor() {}

  ngOnInit(): void {}
}
