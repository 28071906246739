<div class="timer">
  <div class="container">
    <span id="days"> {{ timeDifference > 0 ? daysToDday : "--" }} </span>
    {{ daysToDday != 1 ? "dagen" : "dag" }}
  </div>
  <div class="container">
    <span id="hours"> {{ timeDifference > 0 ? hoursToDday : "--" }} </span>
    uur
  </div>
  <div class="container">
    <span id="minutes"> {{ timeDifference > 0 ? minutesToDday : "--" }} </span>
    {{ minutesToDday != 1 ? "minuten" : "minuut" }}
  </div>
  <div class="container">
    <span id="seconds"> {{ timeDifference > 0 ? secondsToDday : "--" }} </span>
    {{ secondsToDday != 1 ? "seconden" : "seconde" }}
  </div>
</div>
