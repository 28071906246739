import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoProfitRoutingModule } from './go-profit-routing.module';
import { SingleOfferComponent } from './single-offer/single-offer.component';

@NgModule({
  declarations: [SingleOfferComponent],
  imports: [CommonModule, GoProfitRoutingModule],
  exports: [SingleOfferComponent],
})
export class GoProfitModule {}
