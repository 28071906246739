<div class="season-banner-container">
  <div class="season-banner">
    <div class="content">
      <app-generic-button
        class="pulse"
        [scale]="'x2'"
        [title]="'download'"
        [url]="['/binnenkort']"
      >
      </app-generic-button>
      <h2>nu de app</h2>
    </div>
    <img [src]="'/assets/img/seasons/download-app/||season||.svg' | season" />
  </div>
</div>
